import React, { useEffect, useState } from "react";
import { cn } from "../utills";
import TermsModal from "../Components/TermsModal";
import mixpanel from "mixpanel-browser";
import SingleHeader from "../Components/SingleHeader";
import single_offers from "../single_offers.json";
import OfferStart from "../Components/Single/OfferStart";
import OfferEnd from "../Components/Single/OfferEnd";
import OfferFinished from "../Components/Single/OfferFinished";

function SingleOffer({ userID, allParams }) {
  mixpanel.init(
    process.env.REACT_APP_MIXPANEL_TOKEN || "881f9f262c3e0a767103aff45f72566c"
  ); //"881f9f262c3e0a767103aff45f72566c" //63f0238bc98acb392d768d9b97b1f3fe

  const [offerData, setOfferData] = useState(null);

  let offerSlug = window.location.pathname.split("/")[1];

  useEffect(() => {
    let data = single_offers[offerSlug];
    setOfferData(data);
  }, [offerSlug]);

  const [termsModal, setTermsModal] = useState(false);
  const [globalStep, setGlobalStep] = useState(1);

  return (
    <div
      className={cn(
        `w-screen h-screen fixed top-0 z-[60] bg-[#200527] bg-opacity-100 backdrop-blur-md p-4 bg-mob-2 md:bg-desk-2`
      )}
    >
      {offerData && (
        <>
          <SingleHeader step={globalStep} data={offerData} />
          <div className="max-w-[900px] mt-4 md:mt-2 w-full md:w-[90%] m-auto h-[70vh] items-center md:h-full md:block">
            {globalStep === 1 && (
              <OfferStart
                data={offerData}
                setGlobalStep={setGlobalStep}
                userID={userID}
                allParams={allParams}
              />
            )}

            {globalStep === 2 && (
              <OfferEnd
                data={offerData}
                setGlobalStep={setGlobalStep}
                userID={userID}
                allParams={allParams}
              />
            )}

            {globalStep === 3 && (
              <OfferFinished
                data={offerData}
                postback_offer_id={offerData.postbackOfferID}
                setGlobalStep={setGlobalStep}
                userID={userID}
                allParams={allParams}
              />
            )}
          </div>
        </>
      )}

      {termsModal && <TermsModal setOpen={setTermsModal} />}
    </div>
  );
}

export default SingleOffer;
