import React, { useEffect } from "react";
import logotype from "../images/logotype.png";
import { useState } from "react";
import offers from "../offers.json";

function Header({ step }) {
  console.log(step);
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    let allSteps = offers.length + 2;
    let percent = (step / allSteps) * 100;
    if (percent > 100) percent = 100;
    setProgress(percent);
  }, [step]);

  return (
    <div className="max-w-[650px] 2xl:max-w-[781px] w-full m-auto">
      <img
        className="max-w-[160px] 2xl:max-w-[232px] w-full m-auto"
        src={logotype}
        alt="Logotype"
      />

      <div className="max-w-[985px] w-[90%] m-auto mt-4 mb-4">
        <div className="w-full bg-white h-[25px] 2xl:h-[30px] rounded-full relative">
          <div
            className="bg-[#6260E1] h-[25px] 2xl:h-[30px] absolute left-0 top-0 rounded-full duration-300"
            style={{ width: progress + "%" }}
          />
        </div>
        <div className="flex justify-between items-center mt-1 text-[#00FF47] font-bold text-[14px] 2xl:text-[22px] md:text-[20px]">
          <span>R$ 0</span>
          <span>R$ 3</span>
        </div>
      </div>
    </div>
  );
}

export default Header;
