import React from "react";

function TermsModal({ setOpen }) {
  return (
    <div className="flex fixed left-0 top-0 z-10 w-screen h-screen bg-black/30 duration-300 items-center justify-center">
      <div className="w-[90%] md:w-[550px] bg-[#13243E] text-center rounded-[26px] py-8">
        <p className="text-[24px] md:text-[36px] text-white font-regular max-w-[80%] m-auto">
          Você precisa concordar com os termos
        </p>
        <button
          onClick={(e) => setOpen(false)}
          className="px-[25px] py-[10px] bg-[#EA81B6] text-[#13243E] text-[24px] md:text-[36px] rounded-[24px] mt-4"
        >
          Entendi
        </button>
      </div>
    </div>
  );
}

export default TermsModal;
