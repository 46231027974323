import React, { useState } from "react";
import videoCover from "../../images/video-cover.png";
import play from "../../images/play.svg";
import { cn } from "../../utills";
import Checkbox from "../UI/Checkbox";
import VideoModal from "../VideoModal";
import money from "../../images/money.png";

function PromoBlock({ setStep, setTermsModal }) {
  const [videoEnabled, setVideoEnabled] = useState(false);
  const [checked, setChecked] = useState(false);

  return (
    <div className="w-full m-auto max-w-[800px] 2xl:max-w-[985px] mt-4 relative">
      {/*
        <div className="absolute w-full top-[-40px] md:top-[-40px] flex justify-center z-1">
        <img src={money} className="w-[90px] md:w-[140px]" alt="" />
      </div>
        */}

      <div className="w-full p-2 bg-gradient-2 rounded-[22px] relative">
        <h1 className=" text-white text-center text-[18px] 2xl:text-[28px] font-bold hidden">
          Tutorial:
        </h1>

        <div>
          <div
            className="relative  mt-2 cursor-pointer max-w-[300px] 2xl:max-w-[450px] m-auto"
            onClick={(e) => setVideoEnabled(true)}
          >
            <img src={videoCover} alt="Cover" />
            <div className="absolute top-0 left-0 h-full flex justify-center w-full items-center">
              <span className="bg-black/40 px-3 py-1 rounded-lg 2xl:w-[380px] text-center flex items-center justify-center gap-4">
                <img
                  className="w-[25px] md:w-[35px] 2xl:w-[45px]"
                  src={play}
                  alt="play"
                />
                <span className="text-[26px] 2xl:text-[38px] text-white">
                  Assistir tutorial
                </span>
              </span>
            </div>
          </div>
          <h1 className="font-medium mt-2 xl:mt-0 text-white text-[14px] md:text-[24px] 2xl:text-[28px] text-center xl:max-w-[80%] m-auto">
            10% das submissões são falsas. A precisão é crucial — a
            desonestidade é monitorada e penalizada.
          </h1>

          <div className="flex items-center justify-between max-w-[500px] m-auto lg:w-[95%] relative">
            <div className="grid grid-cols-1 gap-3 md:gap-3 mt-[10px]">
              <div className="flex item-center gap-1 md:gap-4">
                <span className="w-[20px] h-[20px] md:w-[30px] md:h-[30px] rounded-full bg-[#EA81B6] font-medium text-[16px] md:text-[18px] flex items-center justify-center">
                  1
                </span>
                <span className="leading-6 md:mt-1 text-white text-[12px] md:text-[18px]">
                  Aceitar os termos de uso;
                </span>
              </div>

              <div className="flex item-center gap-1 md:gap-4">
                <span className="w-[20px] h-[20px] md:w-[30px] md:h-[30px] rounded-full bg-[#EA81B6] font-medium text-[16px] md:text-[18px] flex items-center justify-center">
                  2
                </span>
                <span className="leading-6 md:mt-1 text-white  text-[12px] md:text-[18px]">
                  Preencher os formulários;
                </span>
              </div>

              <div className="flex item-center gap-1 md:gap-4">
                <span className="w-[20px] h-[20px] md:w-[30px] md:h-[30px] rounded-full bg-[#EA81B6] font-medium text-[16px] md:text-[18px] flex items-center justify-center">
                  3
                </span>
                <span className="text-white max-w-full md:mt-1 text-[12px] md:text-[18px] leading-6">
                  Retornar para <b>esta página;</b>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="mt-4 flex justify-center ">
        <Checkbox
          setChecked={setChecked}
          checked={checked}
          label="Concordo em preencher os formulários com informações honestas."
        />
      </div>

      <div className="w-full flex justify-center mt-4">
        <button
          onClick={(e) => {
            if (!checked) return setTermsModal(true);
            setStep(2);
          }}
          className={cn(
            "w-[100%] flex items-center gap-4 font-regular justify-center text-[16px] 2xl:text-[18px] md:text-[24px] duration-300 rounded-[12px] m-auto py-2.5  2xl:py-2 bg-[#5FC88F] text-[#13243E] max-w-[500px] 2xl:max-w-[664px]",
            {
              "bg-[#696969]": !checked,
            }
          )}
        >
          Começar
          <img className="w-[25px]" src={money} alt="Money" />
        </button>
      </div>

      {videoEnabled && <VideoModal setOpen={setVideoEnabled} />}
    </div>
  );
}

export default PromoBlock;
