import React, { useState } from "react";
import { cn } from "../../utills";
import money from "../../images/money.png";
import Checkbox from "../UI/Checkbox";

function OfferEnd({ setTermsModal, setGlobalStep, data }) {
  const [checked, setChecked] = useState(false);

  return (
    <div className="w-full m-auto max-w-[800px] 2xl:max-w-[985px] mt-0 relative">
      <div className="w-full p-4 bg-gradient-2 rounded-[22px] relative pt-6">
        <img
          className="hidden sm:block w-[70%] m-auto"
          src={data.imageDesktop}
          alt="Text Img"
        />
        <img
          className="sm:hidden max-h-[270px] m-auto"
          src={data.imageMobile}
          alt="Text Img"
        />

        <div className="flex items-center my-4 xl:max-w-[90%] m-auto">
          <div className="flex justify-center mt-2 min-w-[50px]">
            <Checkbox
              width={50}
              height={50}
              setChecked={setChecked}
              checked={checked}
            />
          </div>
          <h1
            onClick={(e) => setChecked(!checked)}
            className=" text-white text-center text-[18px] md:text-[22px] 2xl:text-[26px] font-bold max-w-[100%] m-auto mt-2 cursor-pointer"
          >
            {data.checkboxLabel ||
              `Preenchi o formulário da ${data.name} com informações reais eprecisas.`}
          </h1>
        </div>
      </div>
      <div className="w-full flex justify-center mt-[20px] ">
        <button
          onClick={(e) => {
            if (!checked) {
              setTermsModal(true);
              return;
            } else {
              /*
              mixpanel.track("clickthrough_middle", {
                distinct_id: userID,
                offer: data.name,
                offerID: offerURL.split("offer_id=")[1].split("&")[0],
              });
              */
              setGlobalStep(3);
            }
          }}
          className={cn(
            " text-white font-regular text-[18px] md:text-[26px] duration-300 rounded-[22px] m-auto py-2 flex justify-center w-full xl:min-w-[568px] 2xl:min-w-[668px]",
            {
              "bg-[#5FC88F] ": checked,
              "bg-[#696969]": !checked,
            }
          )}
        >
          <span className="max-w-[150px] flex items-center gap-2">
            Finalizar
            <img className="w-[30px]" src={money} alt="Money" />
          </span>
        </button>
      </div>
    </div>
  );
}

export default OfferEnd;
