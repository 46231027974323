import React from "react";
import checkmark from "../../images/checkmark.svg";

function Checkbox({ label, setChecked, checked, width, height }) {
  return (
    <div className="flex items-center gap-2">
      <div
        onClick={() => setChecked(!checked)}
        className={`w-[30px] h-[30px] md:w-[${width || 30}px] md:h-[${
          height || 30
        }px] rounded-[5px] border-[1px] bg-white flex items-center justify-center cursor-pointer`}
      >
        {checked && <img src={checkmark} alt="checkmark" />}
      </div>
      <span
        className="text-white font-bold md:font-regular text-[12px] xl:text-[18px] 2xl:text-[22px] cursor-pointer select-none max-w-[90%]"
        onClick={(e) => setChecked(!checked)}
      >
        {label}
      </span>
    </div>
  );
}

export default Checkbox;
