import React, { useEffect, useState } from "react";
import { cn } from "../utills";
import Header from "../Components/Header";
import TermsModal from "../Components/TermsModal";
import mixpanel from "mixpanel-browser";
import offers from "../offers.json";
import OfferStart from "../Components/Default/OfferStart";
import OfferDone from "../Components/Default/OfferDone";

import PromoBlock from "../Components/Default/PromoBlock";
import OfferFinished from "../Components/Default/OfferFinished";

function MainNew({ userID, allParams }) {
  mixpanel.init(
    process.env.REACT_APP_MIXPANEL_TOKEN || "881f9f262c3e0a767103aff45f72566c"
  ); //"881f9f262c3e0a767103aff45f72566c" //63f0238bc98acb392d768d9b97b1f3fe

  const [globalStep, setGlobalStep] = useState(0);
  const [termsModal, setTermsModal] = useState(false);
  const [step, setStep] = useState(0);
  const [activeOffer, setActiveOffer] = useState(null);
  const [completedForms, setCompletedForms] = useState([]);

  const [offerFinished, setOfferFinished] = useState(false);

  useEffect(() => {
    const completedFormList = offers.filter((offer) => offer.id < step);
    const completedFormNames = completedFormList.map((offer) => offer.name);
    setCompletedForms(completedFormNames);

    let offer = offers[step - 1];
    if (offer) {
      setActiveOffer(offer);
      setGlobalStep(step + 1);
    } else {
      if (activeOffer !== null) {
        setGlobalStep(step + 2);
        setOfferFinished(true);
        setActiveOffer(null);
      }
    }
  }, [step]);

  const [seconds, setSeconds] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setSeconds((prevSeconds) => {
        if (step === offers.length - 1) {
          clearInterval(interval);
          return prevSeconds;
        }
        return prevSeconds + 1;
      });
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    if (step !== offers.length - 1) {
      const handleBeforeUnload = (event) => {
        handleSendEvent();
        event.preventDefault();
        event.returnValue = "";
      };

      window.addEventListener("beforeunload", handleBeforeUnload);

      return () => {
        window.removeEventListener("beforeunload", handleBeforeUnload);
      };
    }
  }, [completedForms, seconds, step]);

  const handleSendEvent = () => {
    /*
    mixpanel.track("user_leave", {
      distinct_id: userID,
      complete_time: seconds + "s",
      completedForms: completedForms.join(", "),
    });
    */
  };

  return (
    <div
      className={cn(
        `w-screen h-screen fixed top-0 z-[60] bg-[#200527] bg-opacity-100 backdrop-blur-md p-4`,
        {
          "bg-mob-1 md:bg-desk-1": activeOffer === null,
          "bg-mob-2 md:bg-desk-2": activeOffer !== null,
        }
      )}
    >
      <Header step={globalStep} />

      <div className="max-w-[900px] mt-2 md:mt-2 w-full md:w-[90%] m-auto h-[70vh] items-center md:h-full md:block">
        {activeOffer === null ? (
          offerFinished === true ? (
            <OfferFinished
              userID={userID}
              allParams={allParams}
              seconds={seconds}
            />
          ) : (
            <InitialSections
              setTermsModal={setTermsModal}
              setActiveOffer={setActiveOffer}
              setGlobalStep={setGlobalStep}
            />
          )
        ) : (
          <OfferSection
            data={activeOffer}
            userID={userID}
            setTermsModal={setTermsModal}
            allParams={allParams}
            setStep={setStep}
            step={step}
          />
        )}
      </div>

      {termsModal && <TermsModal setOpen={setTermsModal} />}
    </div>
  );
}

export default MainNew;

const OfferSection = ({
  data,
  userID,
  allParams,
  setTermsModal,
  setStep,
  step,
}) => {
  const [linkVisited, setLinkVisited] = useState(false);

  useEffect(() => {
    setLinkVisited(false);
  }, [step]);

  return (
    <div>
      {linkVisited ? (
        <OfferDone
          data={data}
          allOffers={offers}
          setTermsModal={setTermsModal}
          setStep={setStep}
          userID={userID}
        />
      ) : (
        <OfferStart
          data={data}
          userID={userID}
          allParams={allParams}
          setLinkVisited={setLinkVisited}
          setStep={setStep}
        />
      )}
    </div>
  );
};

const InitialSections = ({ setActiveOffer, setTermsModal, setGlobalStep }) => {
  const [initialStep, setInitialStep] = useState(1);

  useEffect(() => {
    setGlobalStep(initialStep - 1);

    if (initialStep === 2) {
      setActiveOffer(offers[0]);
    }
  }, [initialStep]);

  return (
    <div className="w-full">
      <PromoBlock setStep={setInitialStep} setTermsModal={setTermsModal} />
    </div>
  );
};
