import React from "react";
import video from "../images/video.mp4";
import { useRef, useEffect } from "react";

function VideoModal({ setOpen }) {
  const videoRef = useRef(null);

  const toggleFullScreen = () => {
    const videoElement = videoRef.current;

    if (videoElement.requestFullscreen) {
      videoElement.requestFullscreen();
    } else if (videoElement.mozRequestFullScreen) {
      /* Firefox */
      videoElement.mozRequestFullScreen();
    } else if (videoElement.webkitRequestFullscreen) {
      /* Chrome, Safari and Opera */
      videoElement.webkitRequestFullscreen();
    } else if (videoElement.msRequestFullscreen) {
      /* IE/Edge */
      videoElement.msRequestFullscreen();
    }
  };

  useEffect(() => {
    toggleFullScreen();
  }, []);

  return (
    <div className="fixed left-0 top-0 z-10 w-screen h-screen bg-black/50 duration-300 items-center justify-center">
      <div className="flex justify-end w-[90%] m-auto mt-4">
        <p
          className="text-white cursor-pointer"
          onClick={(e) => setOpen(false)}
        >
          X
        </p>
      </div>
      <div className="text-center ">
        <video
          ref={videoRef}
          className="m-auto mt-8 w-[90vw] max-h-[85vh]"
          controls
          autoPlay
          playsinline
          src={video}
        ></video>
      </div>
    </div>
  );
}

export default VideoModal;
