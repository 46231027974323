import React, { useEffect } from "react";
import trophy from "../../images/trophy.png";
import { cn, sendPostback } from "../../utills";

function OfferFinished({ userID, postback_offer_id }) {
  useEffect(() => {
    sendPostback(userID, postback_offer_id);
  }, []);

  return (
    <div className="w-full m-auto max-w-[554px] mt-4 relative">
      <div className="w-full p-4 bg-gradient-2 rounded-[22px] relative">
        <img className="w-[200px] m-auto" src={trophy} alt="trophy" />

        <h1 className=" text-white text-center text-[28px] 2xl:text-[36px] font-bold max-w-[100%] m-auto mt-4 2xl:leading-[50px]">
          Formulário preenchido! Agora volte para a TreatCoin.
        </h1>
      </div>
      <div className="w-full flex justify-center mt-[20px] ">
        <button
          onClick={(e) => window.location.replace("https://treatcoin.com/")}
          className={cn(
            "w-[100%] bg-white text-[#13243E] font-regular text-[18px] md:text-[26px] duration-300 rounded-[22px] m-auto py-2 md:py-2"
          )}
        >
          Ir para a TreatCoin
        </button>
      </div>
    </div>
  );
}

export default OfferFinished;
