import React from "react";
import money from "../../images/money.png";
import left from "../../images/left.png";

import { cn } from "../../utills";

function OfferStart({ data, userID, allParams, setGlobalStep }) {
  return (
    <div className="w-full m-auto max-w-[800px] 2xl:max-w-[985px] mt-0 relative">
      <div className="absolute w-full top-[-40px] md:top-[-40px] flex justify-center z-1">
        <img src={money} className="w-[90px] md:w-[100px]" alt="" />
      </div>
      <div className="w-full p-4 bg-gradient-2 rounded-[22px] relative">
        <img
          className=" md:block w-[100px] m-auto mb-0"
          src={data.offerLogo}
          alt="Logotype"
        />

        <p className="w-full rounded-[4px] text-white text-[14px] md:text-[20px] font-bold text-center mb-0">
          {data.description}
        </p>

        <div>
          <div className="relative  mt-2 m-auto cursor-pointer">
            <img
              className="md:hidden max-h-[320px] m-auto rounded-xl"
              src={data.gifLinkMobile}
              alt="Cover"
            />
            <img
              className="hidden md:block rounded-xl max-w-[510px] lg:max-w-[600px] 2xl:max-w-[650px] m-auto"
              src={data.gifLinkDesktop}
              alt="Cover"
            />
          </div>
        </div>
      </div>
      <div className="w-full flex justify-center mt-[20px] ">
        <button
          onClick={(e) => {
            window.open(
              `${data.offerLink.replace(
                "<user-id>",
                userID
              )}&${allParams.toString()}`,
              "_blank"
            );
            setGlobalStep(2);
          }}
          className={cn(
            "w-[100%] max-w-[700px] flex items-center gap-4 font-regular justify-center text-[16px] md:text-[26px] duration-300 rounded-[22px] m-auto py-2 2xl:py-3 bg-[#5FC88F] text-[#232337]"
          )}
        >
          Completar a oferta da {data.name}
          <img className="w-[25px] md:w-[30px]" src={left} alt="" />
        </button>
      </div>
    </div>
  );
}

export default OfferStart;
