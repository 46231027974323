import { clsx } from 'clsx';
import { twMerge } from 'tailwind-merge';
import axios from 'axios';

export function cn(...inputs) {
    return twMerge(clsx(inputs));
}

export async function sendPostback(userID, postback_offer_id) {
    if (postback_offer_id === null) postback_offer_id = '0001'
    try {
        await axios.post(
            `${process.env.REACT_APP_API_URL || 'https://exodusdevelop.com:3023'}/sendPostback`,
            {
                userID, postback_offer_id
            }
        );

        setTimeout(() => {
            window.location.replace("https://treatcoin.com/");
        }, 4000);
    } catch (error) {
        console.log(error)
    }
}