import "./App.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Main from "./Pages/Main";
import SingleOffer from "./Pages/SingleOffer";

function App() {
  const params = new URLSearchParams(window.location.search);
  const userID = params.get("uid");

  return (
    <div>
      <BrowserRouter>
        <Routes>
          <Route
            path="/"
            element={<Main userID={userID} allParams={params} />}
          />
          <Route
            path="/*"
            element={<SingleOffer userID={userID} allParams={params} />}
          />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
